import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    var header = $("#header");
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 100) {
            header.addClass("-dark");
        } else {
            header.removeClass("-dark");
        }
    });    

    $('#mobileButton').on('click', function(){
        $('#body').toggleClass('-no-scroll');
        $(this).toggleClass('-active');
        $('.header-hamburger-bars').toggleClass('-active');
        $('#headerMobile').slideToggle();
    });
    
    $('.menu-item-has-children').on('click', function(event){
        event.stopPropagation();
        $(this).find('.sub-menu').addClass('show-dropdown');
    });

    $('.dropdown-title').on('click', function(event) {
        event.stopPropagation();
        $(this).closest('.sub-menu').removeClass('show-dropdown');
    });
});


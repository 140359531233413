import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-elements-list.-isCarousel').slick({
        dots: false,
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev c-white arrow-alt me-5 pe-4"><i class="fa-solid fa-chevron-left" aria-hidden="true"></i><span class="fa-sr-only">Previous arrow</span></button>',
        nextArrow: '<button type="button" class="slick-next c-white arrow-alt ms-5 ps-4"><i class="fa-solid fa-chevron-right" aria-hidden="true"></i><span class="fa-sr-only">Next arrow</span></button>',
        swipeToSlide: true,
        centerMode: false,
        slidesToShow: 3.2,
        slidesToScroll: 1,
        draggable: true,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        useAutoplayToggleButton: false,
        rows: 0,
        touchThreshold: 20,
        responsive: [
            {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 2.8,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2.5,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2.2,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1.8,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1.5,
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1.2,
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });

    if($(window).width() > 768) {
        $('.m-elements-list-stacked.-isCarousel').slick({
            dots: false,
            arrows: true,
            prevArrow: '<button type="button" class="slick-prev c-white arrow-alt me-5 pe-4"><i class="fa-solid fa-chevron-left" aria-hidden="true"></i><span class="fa-sr-only">Previous arrow</span></button>',
            nextArrow: '<button type="button" class="slick-next c-white arrow-alt ms-5 ps-4"><i class="fa-solid fa-chevron-right" aria-hidden="true"></i><span class="fa-sr-only">Next arrow</span></button>',
            swipeToSlide: true,
            centerMode: false,
            slidesToShow: 3.2,
            slidesToScroll: 1,
            draggable: true,
            infinite: false,
            controls: true,
            autoplay: false,
            autoplaySpeed: 4000,
            pauseOnHover: true,
            useAutoplayToggleButton: false,
            rows: 0,
            touchThreshold: 20,
            responsive: [
                {
                    breakpoint: 1700,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: 2.8,
                    }
                },
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 2.5,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2.2,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 1.8,
                    }
                }
            ]
        });
    };
});
import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-carousel-list.-isCarousel').slick({
        dots: false,
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev me-5"><i class="fa-solid fa-chevron-left" aria-hidden="true"></i><span class="fa-sr-only">Previous arrow</span></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa-solid fa-chevron-right" aria-hidden="true"></i><span class="fa-sr-only">Next arrow</span></button>',
        appendArrows: $('.m-carousel-arrows'),
        swipeToSlide: true,
        slidesToShow: 5,
        draggable: true,
        centerMode: false,
        variableWidth: true,
        slidesToScroll: 1,
        infinite: true,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        useAutoplayToggleButton: false,
        rows: 0,
        touchThreshold: 20,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 4,
                    centerMode: true,
                    variableWidth: true,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    centerMode: true,
                    variableWidth: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    variableWidth: true,
                }
            }
        ]
    });

    $('.m-carousel-list .slick-active').each(function(index) {
        $(this).addClass('-card');
    
        if ($(this).prev('.slick-slide').length > 0) {
          $(this).prev('.slick-slide').addClass('-card');
        }
    
        if ($(this).next('.slick-slide').length > 0) {
          $(this).next('.slick-slide').addClass('-card');
        }
    });
});
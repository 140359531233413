import 'jquery';

$(window).on('load', function() {
    if ($("#pageModal").length > 0) {
        $("#pageModal").delay(10000).fadeIn();

        $("#dismiss-modal").on("click", function(){
            $("#pageModal").fadeOut('slow');
        });

        $(".m-popup-close").on("click", function(){
            $("#pageModal").fadeOut('slow');
        });

        $("#pageModal").on("click", function(event) {
            if (!$(event.target).closest(".modal-content").length) {
                $("#pageModal").fadeOut('slow');
            }
        });
    };
});
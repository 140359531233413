// This file contains all parachute imports, e.g:
import './accordion/accordion';
import './animation/animation';
import './mmenu/mmenu';
import './header/header';
import './splitting/splitting';
import './carousel/carousel';
import './carousel/featured';
import './carousel/cocktails';
import './carousel/testimonials';
import './carousel/horizontal';
import './carousel/cocktail-menu';
import './cocktails/cocktails';
import './modal/modal';
import './carousel/elements';
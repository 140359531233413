import 'jquery';
import { default as $ } from 'jquery';

function addAnimation(selector, animationClass) {
  var windowTop = $(window).scrollTop();
  var windowBottom = windowTop + $(window).height();

  $(selector).each(function() {
    var objectTop = $(this).offset().top;
    var objectBottom = objectTop + $(this).height();

    if (objectBottom > windowTop && objectTop < windowBottom) {
      $(this).addClass(animationClass);
    }
  });
}

$('.block-inners > h2').addClass('addScrollUp');
$('.block-inners > p').addClass('addScrollUp');
$('.m-outer.c-bg-teal .wp-block-column').addClass('addScrollUp');
$('.m-banner h1').attr('data-splitting', 'words').addClass('addScrollUp');

$('h2.wp-block-heading').each(function() {
  if ($(this).closest('.wp-block-column') && !$(this).closest('.m-card').length && !$(this).hasClass('has-text-align-center') && !$(this).closest('.m-popup').length ) {
    $(this).attr('data-splitting', 'words');
  }
});

$('.wp-block-column').each(function() {
  if ($(this).find('.m-package-card').length) {
    addAnimation($(this), "scroll-up");
  }

  if (!$(this).closest('.m-card').length && !$(this).find('.m-vertical').length && !$(this).closest('.m-popup').length) {
    $(this).addClass('addScrollUp');
  }
});

$(document).on('DOMContentLoaded', function() {
  addAnimation(".addAnimation", "fade-in");
  addAnimation(".addSlideLeft", "slide-in");
  addAnimation(".addSlide", "slide-in");
  addAnimation(".cardAnimation", "cardFade");
  addAnimation(".addScrollUp", "scroll-up");

  $('.wp-block-columns').each(function() {
    if ($(this).find('.m-vertical').length) {
      $(this).addClass('cardAnimation');
      $(this).find('.wp-block-column').addClass('-card');
    }
  });

  $(window).on('scroll', function() {
    addAnimation(".addAnimation", "fade-in");
    addAnimation(".addSlideLeft", "slide-in");
    addAnimation(".addSlide", "slide-in");
    addAnimation(".cardAnimation", "cardFade");
    addAnimation(".addScrollUp", "scroll-up");
    addAnimation(".addScrollUp-card", "scroll-up");

    $('.wp-block-column').each(function() {
      if ($(this).find('.m-package-card').length) {
        addAnimation($(this), "scroll-up");
      }
    });

    $('.wp-block-column').each(function() {
      if ($(this).children('h2').length > 0 && $(this).children('p').length > 0) {
        addAnimation($(this), "scroll-up");
      }
    });
  });
});
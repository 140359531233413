import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-horizontal-list.-isCarousel').slick({
        dots: false,
        arrows: false,
        swipeToSlide: true,
        centerMode: false,
        slidesToShow: 2.2,
        slidesToScroll: 1,
        draggable: true,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        useAutoplayToggleButton: false,
        rows: 0,
        touchThreshold: 20,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 1.8,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 1.6,
                }
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 1.4,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1.2,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1.2,
                }
            },
            {
                breakpoint: 920,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.8,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1.6,
                }
            },
            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 1.4,
                }
            },
            {
                breakpoint: 460,
                settings: {
                    slidesToShow: 1.2,
                }
            },
            {
                breakpoint: 360,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });
});
import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    $(".m-cocktails-filters-legend").on("click", function(){
        $(this).find(".fa-angle-down").toggleClass("-upsideDown");
        $(this).closest(".m-cocktails-filters-checkboxes").find(".m-cocktails-filters-list").slideToggle();
    });

    function applyFilters() {
        let checkboxName = $(this).attr('name');
        let checkboxID = $(this).attr('id');
        let htmlContent = `
        <span data-filter="${checkboxID}" class="m-cocktails-selected-item">
            ${checkboxName}
            <i class="fa-solid fa-xmark ms-3"></i>
        </span>`;

        if ($(this).is(':checked')) {
            $('#selectedList').append(htmlContent);
        } else {
            $(`#selectedList span[data-filter="${checkboxID}"]`).remove();
        }
        
        let selectedFilters = {};

        // Collect selected filters
        $('.m-cocktails-filters-checkboxes input[type="checkbox"]:checked').each(function() {
            let taxonomy = $(this).closest('.m-cocktails-filters-section').find('legend').data('legend');
            if (!selectedFilters[taxonomy]) {
                selectedFilters[taxonomy] = [];
            }
            selectedFilters[taxonomy].push($(this).attr('id'));
        });

        let searchQuery = $('#cocktailSearch').val();

        $('.m-cocktails-col-content').addClass('loading');

        // Make AJAX request
        $.ajax({
            url: myAjax.ajaxurl,
            type: 'POST',
            data: {
                action: 'filter_cocktails',
                filters: selectedFilters,
                search_query: searchQuery
            },
            success: function (response) {
                $('.m-cocktails-list').html(response)
                $('.m-cocktails-col-content').removeClass('loading');
            }
        });
    }

    $('.m-cocktails-filters-checkboxes input[type="checkbox"]').change(applyFilters);

    // Trigger filter application on text input (debounced for performance)
    let debounce;
    $('#cocktailSearch').on('input', function () {
        clearTimeout(debounce);
        debounce = setTimeout(applyFilters, 300); // 300ms debounce to avoid excessive requests
    });

    $('#selectedList').on('click', 'span', function() {
        let checkboxID = $(this).attr('data-filter');
        $(this).remove();
        $(`#${checkboxID}`).prop('checked', false).trigger('change');
    });
});

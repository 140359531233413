import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-menu-list.-isCarousel').slick({
        dots: false,
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa-solid fa-angles-left c-red" aria-hidden="true"></i><span class="fa-sr-only">Previous arrow</span></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa-solid fa-angles-right c-red" aria-hidden="true"></i><span class="fa-sr-only">Next arrow</span></button>',
        appendArrows: $('.m-menu-arrows'),
        swipeToSlide: true,
        centerMode: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        variableWidth: false,
        useAutoplayToggleButton: false,
        rows: 0,
        touchThreshold: 20,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });
});